import React from 'react';
import { useIsMobile } from '@utils/hooks';
import { LARGE_WIDTH } from '@constants';
import styles from '../RecommendationCarousel/RecommendationCarousel.module.scss';
import Flex from '../Flex';
import Divider from '../Divider';
import Skeleton from './Skeleton';

const RecommendationCarouselSkeleton = () => {
	const isMobile = useIsMobile({ maxWidth: LARGE_WIDTH });
	return (
		<div className={styles.container} style={{ width: '100vw', paddingTop: '3.2rem', paddingBottom: '3.2rem', paddingLeft: '3.2rem' }}>
			<Skeleton width={300} height={20} type='line' />
			<Divider />
			<Flex maxWidth className={styles.collectionTrack} gap={5} style={{ overflow: isMobile? 'scroll': 'auto', width: '100%' }}>
				{[...Array(isMobile? 9: 5)].map((__, i) => (
					<Skeleton key={i} height={361} width={300} borderRadius={'2rem'} type={'rect'} />
				))}
			</Flex>
		</div>
	);
};

export default RecommendationCarouselSkeleton;
